import { IconProps } from ".";

export const DocumentIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.29531 1C2.29531 0.779086 2.4744 0.6 2.69531 0.6H11.5842C11.8051 0.6 11.9842 0.779087 11.9842 1V2.25424V13C11.9842 13.2209 11.8051 13.4 11.5842 13.4H2.69531C2.4744 13.4 2.29531 13.2209 2.29531 13V2.25424V1Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M4.6582 3L6.50391 3.02979"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path d="M4.65918 7H9.62109" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M4.65918 9H9.62109" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M4.65918 11H9.62109" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};
