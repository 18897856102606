import { FC, useCallback, useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps, Router, Switch, useLocation } from "wouter";
import { AuthenticatedRoute } from "./components/Common/AuthenticatedRoute/AuthenticatedRoute";
import { Hotjar } from "./components/Tracking/Hotjar/Hotjar";
import { Analytics } from "@vercel/analytics/react";
import { ErrorViewer } from "./components/Common/ErrorViewer/ErrorViewer";
import { DEFAULT_ROUTE_AFTER_LOGIN } from "./constants";
import { useNotificationStore } from "./store/notification";
import { ucfirst } from "./utils/string";
import { useQueryParams } from "./hooks/useQueryParams";
import { AuthMachineContext } from "./store/machine/authMachine/authMachine";
import { AuthProcess } from "./components/Auth/AuthProcess";
import { AuthStateType } from "./types/auth";
import env from "./env";
import { ContentLoader } from "./components/Common/ContentLoader/ContentLoader";
import { Toaster } from "./components/ui/toaster";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useToolLocation } from "./hooks/useToolLocation";
import { useMainStore } from "./store/main";
import { installBetterstack } from "./utils/betterstack";
import { Alert, AlertDescription } from "./components/ui/alert";
import { AlertTriangleIcon } from "./components/Icons/AlertTriangleIcon";
import { LazyComponent } from "./components/Common/LazyComponent/LazyComponent";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { MainHeader } from "./components/Common/MainHeader/MainHeader";
import { MainFooter } from "./components/Common/MainFooter/MainFooter";
import { CookieWidget } from "./components/Common/CookieWidget/CookieWidget";
import { useCookieStore } from "./store/cookie";
import { CookiePreferenceType } from "./types/user";
import { CrossIcon } from "@/components/Icons/CrossIcon";
import { HelpViewer } from "./components/Common/HelpViewer/HelpViewer";
import { urlWithCurrentQuery } from "./utils";
import { redirectUsingQuery } from "./utils/location";
import { UserWarningViewer } from "./components/Common/UserWarningViewer/UserWarningViewer";

export const App: FC = () => {
  const [isReady, setIsReady] = useState<boolean>();
  const displayError = useNotificationStore((state) => state.displayError);
  const queryParams = useQueryParams<{
    key: string;
    errorTitle?: string;
    errorMessage?: string;
  }>();
  const hook = useToolLocation();
  const updateToolToLocation = useMainStore((state) => state.updateToolToLocation);
  const [location] = useLocation();
  const [showUnsupportedMessage, setShowUnsupportedMessage] = useState(false);
  const cookiePreferences = useCookieStore((state) => state.cookiePreferences);
  const [isMigrationMessageVisible, setIsMigrationMessageVisible] = useState(true);
  const [isWarningBannerVisible, setIsWarningBannerVisible] = useState(true);

  const handleInitialised = useCallback(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    const { errorTitle, errorMessage } = queryParams;

    if (errorTitle) {
      displayError({
        title: ucfirst(errorTitle),
        message: errorMessage || "Unknown",
        isPublic: true
      });
    }

    installBetterstack();
  }, [queryParams, displayError]);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update");
    }

    updateToolToLocation();
  }, [location, updateToolToLocation]);

  useEffect(() => {
    if (
      !env.FEATUREFLAG_MOBILE_UNSUPPORTED_ENABLED ||
      typeof env.MOBILE_UNSUPPORTED_BREAKPOINT !== "number"
    ) {
      return;
    }

    const handleResize = () => {
      setShowUnsupportedMessage(document.body.clientWidth < env.MOBILE_UNSUPPORTED_BREAKPOINT);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (env.MAINTENANCE_ENABLED) {
    return <LazyMaintenancePage />;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={env.RECAPTCHA_SITE_KEY}
      container={{
        parameters: {
          theme: "dark"
        }
      }}
    >
      <AuthMachineContext.Provider>
        <p className="hidden">Version - {env.APP_VERSION}</p>

        {env.FEATUREFLAG_COIN_MESSAGE_ENABLED && isMigrationMessageVisible && (
          <div className="absolute z-[9999999] flex w-full flex-row items-center gap-5 bg-gray-dark-500 px-5 py-3 text-sm text-white">
            <span className="flex-1 text-center">
              After TGE June 11th 12:00 UTC, io.net platform will migrate to IO Coin payment.
              Cluster creation will be disabled while we perform backend system migration. Thank you
              for your understanding.
            </span>
            <CrossIcon
              className="h-[16px] cursor-pointer text-white"
              onClick={() => {
                setIsMigrationMessageVisible(false);
              }}
            />
          </div>
        )}

        {env.WARNING_BANNER_ENABLED && env.WARNING_BANNER_DESCRIPTION && isWarningBannerVisible && (
          <div className="absolute z-[99999999] flex w-full flex-row items-center gap-5 bg-gray-dark-500 px-5 py-3 text-sm text-white">
            <span className="flex-1 text-center">{env.WARNING_BANNER_DESCRIPTION}</span>
            <CrossIcon
              className="h-[16px] cursor-pointer text-white"
              onClick={() => {
                setIsWarningBannerVisible(false);
              }}
            />
          </div>
        )}

        {env.FEATUREFLAG_VERCEL_ANALYTICS &&
          cookiePreferences.includes(CookiePreferenceType.ANALYTICS) && <Analytics />}
        {env.FEATUREFLAG_HOTJAR_ANALYTICS &&
          cookiePreferences.includes(CookiePreferenceType.ANALYTICS) && <Hotjar />}
        {env.FEATUREFLAG_VERCEL_SPEED_INSIGHTS &&
          cookiePreferences.includes(CookiePreferenceType.ANALYTICS) && <SpeedInsights />}
        <HelpViewer />
        <ErrorViewer />
        <UserWarningViewer />
        <AuthProcess onInitialised={handleInitialised} />
        <Toaster />
        {env.FEATUREFLAG_COOKIE_POLICY && <CookieWidget />}

        {!isReady && <ContentLoader />}
        {isReady && (
          <>
            {showUnsupportedMessage ? (
              <div className="mx-10 flex flex-1 flex-col items-center justify-center">
                <Alert className="flex w-auto flex-row items-center">
                  <AlertTriangleIcon className="h-[16px] text-yellow" />
                  <AlertDescription>
                    Mobile / smaller screen support is coming soon.
                  </AlertDescription>
                </Alert>
              </div>
            ) : (
              <Router hook={hook}>
                <MainHeader />
                <Switch>
                  <Route path="/login" component={LazySignUpPage} />
                  <Route path="/login/signup" component={LazySignUpPage} />
                  <AuthenticatedRoute path="/login/:section" component={LazySignUpPage} />
                  <Route path="/" component={RedirectToHomePage} />

                  {/* Cloud */}
                  <AuthenticatedRoute path="/cloud/home" component={LazyCloudHomePage} />
                  <AuthenticatedRoute
                    path="/cloud/clusters/create-cluster"
                    component={LazyCreateClusterPage}
                    requiresState={AuthStateType.DEPLOY_CLUSTER}
                  />
                  <AuthenticatedRoute
                    path="/cloud/clusters/:id"
                    component={LazyClusterDetailPage}
                  />
                  <AuthenticatedRoute path="/cloud/clusters" component={LazyClustersPage} />
                  <AuthenticatedRoute
                    path="/cloud/mega-clusters"
                    component={LazyMegaClustersPage}
                  />
                  <AuthenticatedRoute
                    path="/cloud/kubernetes-clusters"
                    component={LazyKubernetesClustersPage}
                  />
                  <AuthenticatedRoute
                    path="/cloud/kubernetes-clusters/create-cluster"
                    component={LazyCreateKubernetesClusterPage}
                    requiresState={AuthStateType.DEPLOY_CLUSTER}
                  />
                  <AuthenticatedRoute
                    path="/cloud/kubernetes-clusters/:id"
                    component={LazyKubernetesClusterDetailPagePage}
                  />
                  <AuthenticatedRoute
                    path="/cloud/mega-clusters/create-cluster"
                    component={LazyCreateMegaClusterPage}
                    requiresState={AuthStateType.DEPLOY_CLUSTER}
                  />
                  <AuthenticatedRoute
                    path="/cloud/mega-clusters/:id"
                    component={LazyMegaClusterDetailPage}
                  />

                  {/* Miner */}
                  <AuthenticatedRoute path="/worker/devices" component={LazyWorkerHomePage} />
                  <AuthenticatedRoute
                    path="/worker/devices/create/:id"
                    component={LazyCreateDevicePage}
                    requiresState={AuthStateType.CONNECT_DEVICE}
                  />
                  <AuthenticatedRoute
                    path="/worker/earnings-and-rewards"
                    component={LazyBillingAndRewardsPage}
                  />
                  <AuthenticatedRoute path="/worker/staking" component={LazyStakingPage} />
                  <AuthenticatedRoute
                    path="/worker/integrated-staking"
                    component={LazyStakingIntegratedPage}
                  />

                  {/* Explorer */}
                  <AuthenticatedRoute path="/explorer/home" component={LazyExplorerHomePage} />
                  <AuthenticatedRoute
                    path="/explorer/clusters"
                    component={LazyExplorerClustersPage}
                  />
                  <AuthenticatedRoute
                    path="/explorer/clusters/:id"
                    component={LazyClusterDetailPage}
                  />
                  <AuthenticatedRoute
                    path="/explorer/devices"
                    component={LazyExplorerDevicesPage}
                  />
                  <AuthenticatedRoute path="/explorer/map" component={LazyNetworkMapPage} />
                  <AuthenticatedRoute path="/explorer/inferences" component={LazyInferencePage} />
                  <AuthenticatedRoute
                    path="/explorer/inferences/:id"
                    component={LazyInferenceDetailPage}
                  />
                  <AuthenticatedRoute
                    path="/explorer/block-rewards"
                    component={LazyBlockRewardsPage}
                  />
                  <AuthenticatedRoute
                    path="/explorer/block-rewards/:id"
                    component={LazyBlockRewardsDetailPage}
                  />
                  <AuthenticatedRoute
                    path="/:tool/devices/:id?*"
                    component={LazyDeviceDetailPage}
                  />

                  {/* Account */}
                  <AuthenticatedRoute
                    path="/id/account/settings"
                    component={LazyAccountSettingsPage}
                  />
                  <AuthenticatedRoute
                    path="/id/account/acknowledgment"
                    component={LazyUserAcknowledgmentPage}
                  />
                  <AuthenticatedRoute
                    path="/id/account/transactions-and-balance"
                    component={LazyUserTransactionsAndBalancePage}
                  />
                  <AuthenticatedRoute
                    path="/id/account/security"
                    component={LazySignInSecurityPage}
                  />
                  <AuthenticatedRoute
                    path="/id/account/support"
                    component={LazySupportTicketsPage}
                  />
                  <AuthenticatedRoute
                    path="/id/account/notification"
                    component={LazyNotificationSettingsPage}
                  />
                  <AuthenticatedRoute
                    path="/id/account/io-elements"
                    component={LazyIoElementsPage}
                  />
                  <AuthenticatedRoute path="/id/funds" component={LazyFundsPage} />
                  <AuthenticatedRoute path="/id/funds/:id" component={LazyFundsDetailPage} />
                </Switch>
                <MainFooter />
              </Router>
            )}
          </>
        )}
      </AuthMachineContext.Provider>
    </GoogleReCaptchaProvider>
  );
};

const LazySignUpPage = () => <LazyComponent path="SignUp/SignUpPage" />;
const LazyCloudHomePage = () => <LazyComponent path="Cloud/HomePage" />;
const LazyCreateClusterPage = () => <LazyComponent path="Cloud/CreateClusterPage" />;
const LazyClusterDetailPage = (props: RouteComponentProps) => (
  <LazyComponent {...props} path="Cloud/ClusterDetailPage" />
);
const LazyClustersPage = () => <LazyComponent path="Cloud/ClustersPage" />;
const LazyMegaClustersPage = () => <LazyComponent path="Cloud/MegaClustersPage" />;
const LazyKubernetesClustersPage = () => <LazyComponent path="Cloud/KubernetesClustersPage" />;
const LazyCreateKubernetesClusterPage = () => (
  <LazyComponent path="Cloud/CreateKubernetesClusterPage" />
);
const LazyKubernetesClusterDetailPagePage = (props: RouteComponentProps) => (
  <LazyComponent {...props} path="Cloud/KubernetesClusterDetailPage" />
);
const LazyCreateMegaClusterPage = () => <LazyComponent path="Cloud/CreateMegaClusterPage" />;
const LazyMegaClusterDetailPage = (props: RouteComponentProps) => (
  <LazyComponent {...props} path="Cloud/MegaClusterDetailPage" />
);
const LazyWorkerHomePage = () => <LazyComponent path="Worker/HomePage" />;
const LazyCreateDevicePage = (props: RouteComponentProps) => (
  <LazyComponent {...props} path="Worker/CreateDevicePage" />
);
const LazyBillingAndRewardsPage = () => <LazyComponent path="Worker/BillingAndRewardsPage" />;
const LazyExplorerHomePage = () => <LazyComponent path="Explorer/HomePage" />;
const LazyExplorerClustersPage = () => <LazyComponent path="Explorer/ClustersPage" />;
const LazyExplorerDevicesPage = () => <LazyComponent path="Explorer/DevicesPage" />;
const LazyNetworkMapPage = () => <LazyComponent path="Explorer/NetworkMap" />;
const LazyInferencePage = () => <LazyComponent path="Explorer/InferencePage" />;
const LazyInferenceDetailPage = (props: RouteComponentProps) => (
  <LazyComponent {...props} path="Explorer/InferenceDetailPage" />
);
const LazyDeviceDetailPage = (props: RouteComponentProps) => (
  <LazyComponent {...props} path="Worker/DeviceDetailPage" />
);
const LazyAccountSettingsPage = () => <LazyComponent path="Account/AccountSettingsPage" />;
const LazyUserAcknowledgmentPage = () => <LazyComponent path="Account/UserAcknowledgmentPage" />;
const LazyUserTransactionsAndBalancePage = () => (
  <LazyComponent path="Account/UserTransactionsAndBalancePage" />
);
const LazySignInSecurityPage = () => <LazyComponent path="Account/SignInSecurityPage" />;
const LazySupportTicketsPage = () => <LazyComponent path="Account/SupportTicketsPage" />;
const LazyNotificationSettingsPage = () => (
  <LazyComponent path="Account/NotificationSettingsPage" />
);
const LazyIoElementsPage = () => <LazyComponent path="Common/IoElementsPage" />;

const LazyBlockRewardsPage = () => <LazyComponent path="Explorer/BlockRewardsPage" />;
const LazyBlockRewardsDetailPage = () => <LazyComponent path="Explorer/BlockRewardsDetailPage" />;
const LazyStakingPage = () => <LazyComponent path="Worker/StakingPage" />;
const LazyStakingIntegratedPage = () => <LazyComponent path="Worker/StakingIntegratedPage" />;

const LazyFundsPage = () => {
  return <LazyComponent path="Account/FundsPage" />;
};

const LazyFundsDetailPage = () => {
  return <LazyComponent path="Account/FundsDetailPage" />;
};

const RedirectToHomePage = () => {
  const isLoggedIn = AuthMachineContext.useSelector((state) => state.context.isLoggedIn);
  if (!isLoggedIn) {
    return <Redirect to={urlWithCurrentQuery("/login")} />;
  }
  return <Redirect to={urlWithCurrentQuery(redirectUsingQuery(DEFAULT_ROUTE_AFTER_LOGIN))} />;
};

const LazyMaintenancePage = () => {
  return <LazyComponent path="Common/MaintenancePage" />;
};
