export const shuffle = <T extends []>(unshuffled: T) => {
  const shuffled = unshuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffled as T;
};

export const fillWith = (numberOfItems: number) => {
  return Array.from(Array(numberOfItems).keys());
};

export const pick = <T>(target: T[]) => {
  return target[Math.floor(Math.random() * target.length)] as T;
};

export const clone = <T>(target: T) => {
  return JSON.parse(JSON.stringify(target)) as T;
};

export const closestNumber = (value: number, options: number[]) => {
  return options.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  );
};

export const objectWithkeys = <T>(value: T, keys: string[]) => {
  return Object.fromEntries(
    keys.map((key) => {
      return [key, value[key as keyof typeof value]];
    })
  ) as Partial<T>;
};

export const uniq = <T extends unknown[]>(value: T) => {
  return [...new Set(value)] as T;
};

export const diff = <T extends Record<string, unknown>>(o1: T, o2: T) => {
  return Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key]
    };
  }, {});
};

export const clean = <T>(target: T[]) => {
  const exclude = [null, undefined, "", "undefined", "null"] as unknown[];

  return target.filter((item) => {
    return !exclude.includes(item);
  });
};
