import { clean } from "./utils/array";
import { isNumericOr } from "./utils/number";

const ENV_VARIABLES = {
  ENVIRONMENT: (import.meta.env.VITE_APP_ENVIRONMENT || "development") as string,
  APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN as string,
  APP_VERSION: import.meta.env.VITE_APP_VERSION as string,
  APP_RELEASE_VERSION: import.meta.env.VITE_APP_RELEASE_VERSION as string,
  API_URL: import.meta.env.VITE_APP_API_URL as string,
  API_DEFAULT_TIMEOUT: parseInt(import.meta.env.VITE_APP_API_DEFAULT_TIMEOUT) as number,
  API_DEPLOY_CLUSTER_TIMEOUT: parseInt(
    import.meta.env.VITE_APP_API_DEPLOY_CLUSTER_TIMEOUT
  ) as number,
  AUTH0_RESPONSE_TYPE: import.meta.env.VITE_APP_AUTH0_RESPONSE_TYPE as string,
  AUTH0_DOMAIN: import.meta.env.VITE_APP_AUTH0_DOMAIN as string,
  AUTH0_CLIENT_ID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID as string,
  AUTH0_AUDIENCE: import.meta.env.VITE_APP_AUTH0_AUDIENCE as string,
  AUTH0_SCOPE: import.meta.env.VITE_APP_AUTH0_SCOPE as string,
  SUPABASE_URL: import.meta.env.VITE_APP_SUPABASE_URL as string,
  SUPABASE_KEY: import.meta.env.VITE_APP_SUPABASE_KEY as string,
  BC8_URL: import.meta.env.VITE_APP_BC8_URL as string,
  BC8_TOKEN: import.meta.env.VITE_APP_BC8_TOKEN as string,
  RECAPTCHA_SITE_KEY: import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY as string,
  IS_PAYOUT: import.meta.env.VITE_APP_FEATUREFLAG_IS_PAYOUT === "true",
  IS_STRIPE: import.meta.env.VITE_APP_FEATUREFLAG_IS_STRIPE === "true",
  IS_HEAD_NODES_CHECK_DISABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_IS_HEAD_NODES_CHECK_DISABLED === "true",
  DEVICE_CONNECTION_CHECK_INTERVAL_IN_SEC: import.meta.env
    .VITE_APP_FEATUREFLAG_DEVICE_CONNECTION_CHECK_INTERVAL_IN_SEC,
  INTERCOM_ENABLED: import.meta.env.VITE_APP_INTERCOM_ENABLED as string,
  INTERCOM_APP_ID: import.meta.env.VITE_APP_INTERCOM_APP_ID as string,
  INTERCOM_APP_KEY: import.meta.env.VITE_APP_INTERCOM_APP_ID as string,
  BETTERSTACK_ENABLED: import.meta.env.VITE_APP_BETTERSTACK_ENABLED === "true",
  SECTION_SUBDOMAINS_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_SECTION_SUBDOMAINS_ENABLED === "true",
  LOGIN_SUBDOMAIN: import.meta.env.VITE_APP_LOGIN_SUBDOMAIN as string,
  MOBILE_UNSUPPORTED_BREAKPOINT: parseInt(
    import.meta.env.VITE_APP_MOBILE_UNSUPPORTED_BREAKPOINT,
    10
  ) as number,
  FEATUREFLAG_FIXED_NUMBER_FORMATTING:
    import.meta.env.VITE_APP_FEATUREFLAG_FIXED_NUMBER_FORMATTING === "true",
  FEATUREFLAG_POINTS_ENABLED: import.meta.env.VITE_APP_FEATUREFLAG_POINTS_ENABLED === "true",
  FEATUREFLAG_POINTS_NOTICE_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_POINTS_NOTICE_ENABLED === "true",
  FEATUREFLAG_POINTS_SEASONS_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_POINTS_SEASONS_ENABLED === "true",
  POINTS_SEASONS_LIST: import.meta.env.VITE_APP_POINTS_SEASONS_LIST.split(",") as string[],
  FEATUREFLAG_MOBILE_UNSUPPORTED_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_MOBILE_UNSUPPORTED_ENABLED === "true",
  FEATUREFLAG_SOLANA_UPDATE_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_SOLANA_UPDATE_ENABLED === "true",
  FEATUREFLAG_RESTRICT_EXPLORER_WORKER_RESULTS_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_RESTRICT_EXPLORER_WORKER_RESULTS_ENABLED === "true",
  FEATUREFLAG_VERCEL_ANALYTICS: import.meta.env.VITE_APP_FEATUREFLAG_VERCEL_ANALYTICS === "true",
  FEATUREFLAG_SENTRY_ANALYTICS: import.meta.env.VITE_APP_FEATUREFLAG_SENTRY_ANALYTICS === "true",
  FEATUREFLAG_HOTJAR_ANALYTICS: import.meta.env.VITE_APP_FEATUREFLAG_HOTJAR_ANALYTICS === "true",
  FEATUREFLAG_VERCEL_SPEED_INSIGHTS:
    import.meta.env.VITE_APP_FEATUREFLAG_VERCEL_SPEED_INSIGHTS === "true",
  FEATUREFLAG_COOKIE_POLICY: import.meta.env.VITE_APP_FEATUREFLAG_COOKIE_POLICY === "true",
  FEATUREFLAG_WALLET_ADDRESS_API_ONLY:
    import.meta.env.VITE_APP_FEATUREFLAG_WALLET_ADDRESS_API_ONLY === "true",
  FEATUREFLAG_CLUSTER_DEPLOYMENT_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_CLUSTER_DEPLOYMENT_ENABLED === "true",
  FEATUREFLAG_COIN_MESSAGE_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_COIN_MESSAGE_ENABLED === "true",
  FEATUREFLAG_AMPLITUDE_TRACKING_ENABLED:
    import.meta.env.VITE_APP_FEATUREFLAG_AMPLITUDE_TRACKING_ENABLED === "true",
  BETTERSTACK_SOURCE_TOKEN: import.meta.env.VITE_APP_BETTERSTACK_SOURCE_TOKEN as string,
  UNVERIFIED_DEVICE_TEXT: import.meta.env.VITE_APP_UNVERIFIED_DEVICE_TEXT as string,
  LOGIN_OPTIONS: import.meta.env.VITE_APP_LOGIN_OPTIONS
    ? import.meta.env.VITE_APP_LOGIN_OPTIONS.split(",")
    : undefined,
  LOGIN_VERSION: import.meta.env.VITE_APP_LOGIN_VERSION as string,
  SENTRY_DSN: import.meta.env.VITE_APP_SENTRY_DSN as string,
  SENTRY_TRACES_SAMPLE_RATE: parseFloat(import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE),
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: parseFloat(
    import.meta.env.VITE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
  ),
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: parseFloat(
    import.meta.env.VITE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
  ),
  IS_CYPRESS: import.meta.env.IS_CYPRESS === "true",
  EXPLORER_USE_DAILY_EARNINGS: import.meta.env.VITE_APP_EXPLORER_USE_DAILY_EARNINGS === "true",
  DEVICE_BLOCK_REWARDS_CHECKLIST:
    import.meta.env.VITE_APP_DEVICE_BLOCK_REWARDS_CHECKLIST === "true",
  DEVICE_BLOCK_REWARDS_CHECKLIST_WARNING: import.meta.env
    .VITE_APP_DEVICE_BLOCK_REWARDS_CHECKLIST_WARNING,
  AETHIR_HARDCODED_DATA_JSON: import.meta.env.VITE_APP_AETHIR_HARDCODED_DATA_JSON
    ? JSON.parse(import.meta.env.VITE_APP_AETHIR_HARDCODED_DATA_JSON)
    : {},
  MIN_CLUSTER_CREATION_TIMEOUT: import.meta.env.VITE_APP_MIN_CLUSTER_CREATION_TIMEOUT,
  CLUSTER_IDE_BUTTONS_TIMEOUT: import.meta.env.VITE_APP_CLUSTER_IDE_BUTTONS_TIMEOUT,
  AMPLITUDE_KEY: import.meta.env.VITE_APP_AMPLITUDE_KEY,
  STAKING_NETWORK: import.meta.env.VITE_APP_STAKING_NETWORK,
  STAKING_NETWORK_API_ENDPOINT: import.meta.env.VITE_APP_STAKING_NETWORK_API_ENDPOINT,
  STAKING_CONTRACT_ADDRESS: import.meta.env.VITE_APP_STAKING_CONTRACT_ADDRESS,
  STAKING_MINT_TOKEN_ADDRESS: import.meta.env.VITE_APP_STAKING_MINT_TOKEN_ADDRESS,
  STAKING_SUPER_ADMIN_PUBLIC_KEY: import.meta.env.VITE_APP_STAKING_SUPER_ADMIN_PUBLIC_KEY,
  STAKING_SHOW_ONLY_ONLINE_DEVICES:
    import.meta.env.VITE_APP_STAKING_SHOW_ONLY_ONLINE_DEVICES === "true",
  STAKING_UNSTAKING_ENABLED: import.meta.env.VITE_APP_STAKING_UNSTAKING_ENABLED === "true",
  STAKING_COMPUTE_UNIT_LIMIT: parseFloat(import.meta.env.VITE_APP_STAKING_COMPUTE_UNIT_LIMIT),
  STAKING_COMPUTE_UNIT_PRICE: parseFloat(import.meta.env.VITE_APP_STAKING_COMPUTE_UNIT_PRICE),
  MAINTENANCE_ENABLED: import.meta.env.VITE_APP_MAINTENANCE_ENABLED === "true",
  MAINTENANCE_TITLE: import.meta.env.VITE_APP_MAINTENANCE_TITLE,
  MAINTENANCE_DESCRIPTION: import.meta.env.VITE_APP_MAINTENANCE_DESCRIPTION,
  CLUSTER_CREATION_RAY_MIN_HARDWARE: isNumericOr(
    import.meta.env.VITE_APP_CLUSTER_CREATION_RAY_MIN_HARDWARE,
    4
  ),
  CLUSTER_CREATION_RAY_MAX_HARDWARE: isNumericOr(
    import.meta.env.VITE_APP_CLUSTER_CREATION_RAY_MAX_HARDWARE,
    Number.POSITIVE_INFINITY
  ),
  CLUSTER_CREATION_KUBERNETES_MIN_HARDWARE: isNumericOr(
    import.meta.env.VITE_APP_CLUSTER_CREATION_KUBERNETES_MIN_HARDWARE,
    10
  ),
  CLUSTER_CREATION_KUBERNETES_MAX_HARDWARE: isNumericOr(
    import.meta.env.VITE_APP_CLUSTER_CREATION_KUBERNETES_MAX_HARDWARE,
    20
  ),
  CLUSTER_CREATION_MEGA_MIN_HARDWARE: isNumericOr(
    import.meta.env.VITE_APP_CLUSTER_CREATION_MEGA_MIN_HARDWARE,
    1
  ),
  CLUSTER_CREATION_MEGA_MAX_HARDWARE: isNumericOr(
    import.meta.env.VITE_APP_CLUSTER_CREATION_MEGA_MAX_HARDWARE,
    Number.POSITIVE_INFINITY
  ),
  WARNING_BANNER_ENABLED: import.meta.env.VITE_APP_WARNING_BANNER_ENABLED === "true",
  WARNING_BANNER_DESCRIPTION: import.meta.env.VITE_APP_WARNING_BANNER_DESCRIPTION,
  BLOCK_REWARD_AIRDROP_LIST: clean(
    `${import.meta.env.VITE_APP_BLOCK_REWARD_AIRDROP_LIST}`.split(",")
  )
};

export default ENV_VARIABLES;
