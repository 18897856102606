import { useEffect, useState } from "react";
import { WarningBanner } from "../WarningBanner/WarningBanner";
import { useUserStore } from "@/store/user";
import { useLocation } from "wouter";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";

export const UserWarningViewer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);
  const userInfo = useUserStore((state) => state.userInfo);
  const [message, setMessage] = useState<React.ReactNode>();
  const [location] = useLocation();
  const profile = AuthMachineContext.useSelector((state) => state.context.profile);

  useEffect(() => {
    if (userInfo?.blockRewardsSuspicious) {
      if (WHITELISTED_EMAIL_ADDRESSES.includes(`${profile?.email}`)) {
        setMessage(
          "Your account has been manually reviewed. You are now clear to claim your July block rewards!"
        );
      } else {
        setMessage(
          <>
            Your account activity needs to be manually reviewed before block rewards distribution.
            Please reach out to our hotline Email:&nbsp;
            <a href="mailto:blockrewards@io.net" className="text-yellow">
              blockrewards@io.net
            </a>
            &nbsp;or create a support ticket on our intercom.
          </>
        );
      }
      setIsOpen(true);
    }
  }, [userInfo, profile]);

  useEffect(() => {
    if (location === "/worker/earnings-and-rewards") {
      fetchUserInfo();
    }
  }, [location, fetchUserInfo]);

  return (
    <WarningBanner
      isOpen={isOpen}
      message={message}
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
};

const WHITELISTED_EMAIL_ADDRESSES = [
  "manualtests@io.net",
  "lx8623001@gmail.com",
  "gxfcgxfccai@gmail.com",
  "852073607@qq.com",
  "dicksoncheung00@gmail.com",
  "ll3272517228@gmail.com",
  "ioxiaosen@gmail.com",
  "mark.shtepa@gmail.com",
  "chenchiyuan03@gmail.com",
  "coinfuture20241@gmail.com",
  "coinfuture20244@gmail.com",
  "dj339472578@gmail.com",
  "hymir4test@gmail.com",
  "hi@edufourx.store",
  "ydcgga@gmail.com",
  "guanjiny2024@gmail.com",
  "banrusan@gmail.com",
  "lli213272@gmail.com",
  "bio.xxplus@gmail.com",
  "ydcgga@gmail.com",
  "hi@edufourx.store",
  "1842873126@qq.com",
  "dicksoncheung00@gmail.com",
  "lx8623001@gmail.com",
  "bio.xxplus@gmail.com",
  "chenyibin96@gmail.com",
  "linrz9290@gmail.com",
  "chenchiyuan03@gmail.com",
  "olayaaharonof@gmail.com",
  "lli213272@gmail.com",
  "g5mover1990@gmail.com",
  "gxfcgxfccai@gmail.com",
  "852073607@qq.com",
  "mark.shtepa@gmail.com",
  "zhang7700@foxmail.com",
  "ioxiaosen@gmail.com",
  "banrusan@gmail.com",
  "coinfuture20244@gmail.com",
  "wjhjy110@gmail.com",
  "dj339472578@gmail.com",
  "ll3272517228@gmail.com",
  "coinfuture20241@gmail.com",
  "hymir4test@gmail.com",
  "guanjiny2024@gmail.com"
];
