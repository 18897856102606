import env from "@/env";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { autocapturePlugin } from "@amplitude/plugin-autocapture-browser";
import { AnalyticsEventType } from "@/constants/analytics";

let isInitialised = false;

// https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-1#user-properties
// https://community.amplitude.com/data-instrumentation-57/creating-and-updating-a-user-property-1118
export const initialiseAmplitude = async (options?: amplitude.Types.BrowserOptions) => {
  if (!env.FEATUREFLAG_AMPLITUDE_TRACKING_ENABLED) {
    return;
  }

  if (isInitialised) {
    return;
  }
  isInitialised = true;

  try {
    console.log("initialised amplitude", options);
    amplitude.init(env.AMPLITUDE_KEY, options);

    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);
    amplitude.add(autocapturePlugin());
  } catch (e) {
    console.log(e);
  }
};

let USER_OPTIONS: Record<string, unknown> = {};

export const updateAmplitudeUserOptions = (userOptions: Record<string, unknown>) => {
  const identifyEvent = new amplitude.Identify();

  for (const [key, value] of Object.entries(userOptions)) {
    USER_OPTIONS[key] = value;
    identifyEvent.set(key, value as string);
  }

  amplitude.identify(identifyEvent);

  console.log("amplitude user options updated", USER_OPTIONS);
};

export const resetAmplitudeUserOptions = () => {
  USER_OPTIONS = {};
};

export const trackAmplitudeEvent = (
  eventName: AnalyticsEventType,
  options?: Record<string, unknown>
) => {
  try {
    amplitude.track(eventName, {
      ...options,
      environment: env.ENVIRONMENT
    });
    console.log(`tracked amplitude event - ${eventName}`, options);
  } catch (e) {
    console.log("amplitude track event error", e);
  }
};
