import { CreateClusterButton } from "@/components/HomePage/CreateClusterButton/CreateClusterButton";
import { UserButton } from "../UserButton/UserButton";
import { MainLogo } from "../MainLogo/MainLogo";
import { MainMenu } from "../MainMenu/MainMenu";
import { useMainStore } from "@/store/main";
import { UserNotificationsButton } from "../UserNotificationsButton/UserNotificationsButton";
import { CreateNewDeviceButton } from "../CreateNewDeviceButton/CreateNewDeviceButton";
import { MobileNav } from "../MobileNav/MobileNav";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { IoNetIcon } from "@/components/Icons/IoNetIcon";
import { BalanceType } from "@/types/user";
import env from "@/env";
import { Button } from "@/components/ui/button";
import { UserBalance } from "../UserBalance/UserBalance";
import { useLocation } from "wouter";

export const MainHeader: React.FC = () => {
  const [, setLocation] = useLocation();

  const tool = useMainStore((state) => state.tool);
  const { value } = tool || {};
  const isLoggedIn = AuthMachineContext.useSelector((state) => state.context.isLoggedIn);
  if (!isLoggedIn) {
    return (
      <div className="flex flex-row border-b border-gray-dark-900 bg-gray-dark-600 py-6 pl-6 lg:pl-20">
        <IoNetIcon className="h-[14px] text-white" />
      </div>
    );
  }
  return (
    <div className="sticky top-0 z-[2] bg-gray-dark-600">
      <div
        className="flex flex-row items-center gap-3 px-6 py-3 pl-5 dark:border-b-gray-dark-500"
        data-testid={"MainHeader_wrapper"}
      >
        <MainLogo />
        <div className="flex-1"></div>
        {(value === "cloud" || value === "worker") && (
          <div className="hidden items-center gap-3 lg:flex">
            {value === "cloud" && (
              <>
                <UserBalance type={BalanceType.IOCLOUD} />
                <div className="h-[12px] w-[1px] bg-gray-dark-1200"></div>
                <UserBalance type={BalanceType.CLOUD} />
              </>
            )}
            {value === "worker" && <UserBalance type={BalanceType.IOWORKER} />}
          </div>
        )}
        <Button size="xs" variant="outline" onClick={() => setLocation("/id/funds")}>
          Manage funds
        </Button>
        {value === "cloud" && (
          <div className="hidden lg:block">
            {env.FEATUREFLAG_CLUSTER_DEPLOYMENT_ENABLED && <CreateClusterButton />}
          </div>
        )}
        {value === "worker" && (
          <>
            <span className="hidden h-[16px] w-[1px] bg-gray-dark-1200 lg:flex"></span>
            <div className="hidden pr-3 lg:block">
              <CreateNewDeviceButton />
            </div>
          </>
        )}
        <a
          href="https://docs.io.net/"
          target="_blank"
          className="text-gray transition-colors hover:text-white max-lg:hidden"
        >
          Docs
        </a>
        <div className="px-3">
          <UserNotificationsButton />
        </div>
        <div className="hidden pr-3 lg:block">
          <UserButton />
        </div>
        <div className="lg:hidden">
          <MobileNav />
        </div>
      </div>
      <MainMenu />
    </div>
  );
};
