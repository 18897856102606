import env from "@/env";
import * as Sentry from "@sentry/react";
import { isNumericOr } from "./number";

// https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
export const initialiseSentry = () => {
  if (!env.FEATUREFLAG_SENTRY_ANALYTICS || !env.SENTRY_DSN) {
    return;
  }
  if (
    env.APP_RELEASE_VERSION === "0.0.0" ||
    window.location.hostname === "localhost" ||
    env.ENVIRONMENT === "development"
  ) {
    return;
  }

  const options = {
    release: `cloud-io-net@${env.APP_RELEASE_VERSION}`,
    environment: "production",
    dsn: env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"]
      }),
      Sentry.moduleMetadataIntegration(),
      Sentry.contextLinesIntegration(),
      Sentry.globalHandlersIntegration({
        onerror: false,
        onunhandledrejection: false
      })
    ],
    denyUrls: [
      "https://www.gstatic.com",
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i
    ],
    ignoreErrors: [
      "User rejected the request.",
      "WalletConnectionError",
      "WalletSendTransactionError",
      "WalletSignTransactionError",
      "WalletDisconnectedError",
      "WalletNotReadyError",
      "WalletAccountError",
      "WalletDisconnectionError",
      "Amplitude Logger [Error]",
      "Cannot assign to read only property 'solana'",
      "Sender: Failed to get initial state. Please report this bug.",
      "Unable to preload CSS",
      "layers.country-fills.paint.fill-color: Expected at least 3 arguments, but found only 1.",
      "error loading dynamically imported module",
      'can\'t redefine non-configurable property "bitcoin"',
      'can\'t redefine non-configurable property "ethereum"',
      'can\'t redefine non-configurable property "solana"',
      "Error redefining provider into window.ethereum",
      "Load failed https://api.mapbox.com",
      "getConnectWalletInfo timeout: 3000",
      "Failed to fetch dynamically imported module",
      "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "Map is not supported by this browser",
      "SyntaxError: Unexpected EOF",
      "MetaMask: Received invalid network parameters.",
      "Failed to fetch TypeError",
      "AbortError: The connection was closed.",
      "empty host rule",
      "[KEKEAD str]",
      /^Rage Click$/,
      /^Unexpected EOF$/,
      /^Method not found$/,
      /^Timeout$/
    ],
    beforeSend(event, hint) {
      const error = hint.originalException as Error | undefined;

      if (error && error.message) {
        const { message } = error;

        if (message.indexOf("Importing a module script failed") > -1) {
          return null;
        }
      }

      return event;
    },
    // https://docs.sentry.io/platforms/javascript/configuration/options/
    sampleRate: 1.0,

    // Performance Monitoring
    tracesSampleRate: isNumericOr(env.SENTRY_TRACES_SAMPLE_RATE, 1.0),
    // Session Replay
    replaysSessionSampleRate: isNumericOr(env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE, 0.1),
    replaysOnErrorSampleRate: isNumericOr(env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, 1.0)
  } as Sentry.BrowserOptions;

  console.log("Initialised Sentry", options);

  Sentry.init(options);
};
