import { useState } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { useMainStore } from "@/store/main";
import { Button } from "@/components/ui/button";
import { IoElementsList } from "@/components/Account/IoElementsList";
import { useLocation } from "wouter";

export const MainLogo = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const tool = useMainStore((state) => state.tool);
  const [location] = useLocation();

  return (
    <>
      <HoverCard data-testid={"MainLogo_popover"} open={isPopoverOpen} openDelay={300}>
        <HoverCardTrigger
          onMouseEnter={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <Button
            size="sm"
            variant="outline"
            className={`h-[32px] gap-2 rounded-[8px] bg-gray-dark-1400 duration-300 hover:border-gray-dark-1200 hover:bg-black ${isPopoverOpen ? "border-gray-dark-1200" : "border-gray-dark-900"}`}
          >
            {tool && (
              <>
                <img
                  src={`/assets/images/${tool.value}-logo.svg`}
                  className="min-[12px]"
                  alt={tool.value}
                />
                <img
                  src={"/assets/images/chevron-up-down.svg"}
                  className="min-[12px]"
                  alt="chevron"
                />
              </>
            )}
          </Button>
          <HoverCardContent className="mt-[-8px] border-none bg-transparent p-0 pt-4" align="start">
            <div
              data-testid={"MainLogo_popoverPanel"}
              className={`grid w-[280px] grid-cols-1 gap-2 rounded-lg border border-gray-dark-1200 bg-white p-3 dark:bg-black lg:w-[650px] lg:grid-cols-2`}
            >
              <IoElementsList
                onClick={(item) => {
                  if (item.href === location) {
                    setIsPopoverOpen(false);
                  }
                }}
              />
            </div>
          </HoverCardContent>
        </HoverCardTrigger>
      </HoverCard>
    </>
  );
};
