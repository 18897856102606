import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog";
import React from "react";

type ConnectWorkerDisabledModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ConnectWorkerDisabledModal: React.FC<ConnectWorkerDisabledModalProps> = ({
  isOpen,
  onClose
}) => {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="p-0">
        <AlertDialogHeader className="flex flex-row items-start p-5 pb-0">
          <AlertDialogTitle className="flex-1 text-center text-lg font-medium">
            Connecting new workers is temporarily not allowed
          </AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription className="px-5 text-center text-sm text-gray-dark-1000">
          We are limiting the ability to connect new workers in order to ensure network stability
          and maintain high quality of our services. Our team is working hard to allow adding more
          workers to our network, join our{" "}
          <a
            href="https://discord.com/invite/ionetofficial"
            target="_blank"
            className="cursor-pointer text-blue-light-300"
          >
            Discord
          </a>{" "}
          to be first to know when we enable more workers to join the network!
        </AlertDialogDescription>
        <AlertDialogFooter className="items-center rounded-b-lg border-t border-gray-dark-1100 bg-black px-5 py-3">
          <AlertDialogAction
            onClick={onClose}
            className="border border-gray-dark-900 bg-gray-dark-600 text-white transition-all hover:bg-white hover:text-black"
          >
            Close
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
