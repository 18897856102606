import { useDeviceStore } from "@/store/device";
import { useState } from "react";
import { useNotificationStore } from "@/store/notification";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { Button, ButtonProps } from "@/components/ui/button";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import { Loader } from "../Loader/Loader";
import { cn } from "@/utils/styles";
import { AuthEventType } from "@/types/auth";
import { ConnectWorkerDisabledModal } from "../ConnectWorkerDisabledModal/ConnectWorkerDisabledModal";

export const CreateNewDeviceButton = ({
  testId = "CreateNewDeviceButton",
  className,
  size = "xs",
  label = "Connect New Worker",
  loaderColor = "#000",
  ...props
}: ButtonProps & {
  label?: string;
  testId?: string;
  loaderColor?: string;
}) => {
  const authActorRef = AuthMachineContext.useActorRef();
  const createId = useDeviceStore((state) => state.createId);
  const displayError = useNotificationStore((state) => state.displayError);
  const [isLoading, setIsLoading] = useState(false);
  const [showDisabledModal, setShowDisabledModal] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleCreateDevice = async () => {
    try {
      setIsLoading(true);
      if (!executeRecaptcha) {
        throw false;
      }
      const recaptchaToken = await executeRecaptcha("yourAction");
      const deviceId = await createId(recaptchaToken);

      authActorRef.send({
        type: AuthEventType.CONNECT_DEVICE,
        value: deviceId
      });
    } catch (e) {
      if (e instanceof Error) {
        if (e?.message?.toLowerCase() === "network capacity is reached.") {
          setShowDisabledModal(true);
        } else {
          displayError({
            title: "ERROR",
            message: e.message,
            isPublic: true
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        disabled={!executeRecaptcha || isLoading}
        data-testid={testId}
        onClick={handleCreateDevice}
        className={cn("gap-2", className)}
        size={size}
        {...props}
      >
        <span>{label}</span>
        {isLoading ? (
          <Loader className="!h-[14px] !w-[14px]" color={loaderColor} />
        ) : (
          <PlusIcon className="w-[14px]" />
        )}
      </Button>
      <ConnectWorkerDisabledModal
        isOpen={showDisabledModal}
        onClose={() => {
          setShowDisabledModal(false);
        }}
      />
    </>
  );
};
