import { AethirIcon } from "@/components/Icons/AethirIcon";
import { AlertTriangleIcon } from "@/components/Icons/AlertTriangleIcon";
import { AmdIcon } from "@/components/Icons/AmdIcon";
import { AppleIcon } from "@/components/Icons/AppleIcon";
import { ErrorCircleIcon } from "@/components/Icons/ErrorCircleIcon";
import { FileCoinCircleIcon } from "@/components/Icons/FileCoinCircleIcon";
// import { InfoCircleIcon } from "@/components/Icons/InfoCircleIcon";
import { IntelIcon } from "@/components/Icons/IntelIcon";
import { IoIcon } from "@/components/Icons/IoIcon";
import { KubeflowIcon } from "@/components/Icons/KubeflowIcon";
import { LambdaIcon } from "@/components/Icons/LambdaIcon";
import { LudwigClusterIcon } from "@/components/Icons/LudwigClusterIcon";
import { MacOSIcon } from "@/components/Icons/MacOSIcon";
import { NvidiaIcon } from "@/components/Icons/NvidiaIcon";
import { PlyTorchIcon } from "@/components/Icons/PlyTorchIcon";
import { PredibaseIcon } from "@/components/Icons/PredibaseIcon";
import { RayClusterIcon } from "@/components/Icons/RayClusterIcon";
import { RenderNetworkIcon } from "@/components/Icons/RenderNetworkIcon";
import { TickCircleIcon } from "@/components/Icons/TickCircleIcon";
import { UbuntuOSIcon } from "@/components/Icons/UbuntuOSIcon";
import { UnityIcon } from "@/components/Icons/UnityIcon";
import { UnrealIcon } from "@/components/Icons/Unrealcon";
import { WindowsOSIcon } from "@/components/Icons/WindowsOSIcon";
import { NotificationType } from "@/types/user";

export const HARDWARE_MAP: {
  [k: string]: {
    hardwareManufacturerColor: string;
    hardwareManufacturerIcon: typeof NvidiaIcon;
    hardwareManufacturer: string;
  };
} = {
  apple: {
    hardwareManufacturerColor: "text-white",
    hardwareManufacturerIcon: AppleIcon,
    hardwareManufacturer: "Apple"
  },
  amd: {
    hardwareManufacturerColor: "text-red",
    hardwareManufacturerIcon: AmdIcon,
    hardwareManufacturer: "AMD"
  },
  intel: {
    hardwareManufacturerColor: "bg-red",
    hardwareManufacturerIcon: IntelIcon,
    hardwareManufacturer: ""
  },
  nvidia: {
    hardwareManufacturerColor: "text-green-dark-100",
    hardwareManufacturerIcon: NvidiaIcon,
    hardwareManufacturer: "Nvidia"
  }
};

export const getHardware = (name: string) => {
  const hardware = HARDWARE_MAP[`${`${name}`.toLowerCase()}` as keyof typeof HARDWARE_MAP];

  if (hardware) {
    return hardware;
  }
};

export const getSupplier = (name: string) => {
  const supplier = SUPPLIER_MAP[`${`${name}`.toLowerCase()}` as keyof typeof SUPPLIER_MAP];

  if (supplier) {
    return supplier;
  }

  return Object.values(SUPPLIER_MAP)[0];
};

const SUPPLIER_MAP: {
  [k: string]: {
    icon: typeof NvidiaIcon;
  };
} = {
  "io.net": {
    icon: IoIcon
  },
  filecoin: {
    icon: FileCoinCircleIcon
  },
  lambda: {
    icon: LambdaIcon
  },
  "render network": {
    icon: RenderNetworkIcon
  },
  "aethir network": {
    icon: AethirIcon
  },
  aethir: {
    icon: AethirIcon
  }
};

export const getService = (name: string) => {
  const service = SERVICE_MAP[`${`${name}`.toLowerCase()}` as keyof typeof SERVICE_MAP];

  if (service) {
    return service;
  }

  return Object.values(SERVICE_MAP)[0];
};

const SERVICE_MAP = {
  "pytorch fsdp": {
    icon: PlyTorchIcon
  },
  "ray app": {
    icon: RayClusterIcon
  },
  predibase: {
    icon: PredibaseIcon
  },
  ludwig: {
    icon: LudwigClusterIcon
  },
  "io native app": {
    icon: IoIcon
  },
  "unreal engine 5": {
    icon: UnrealIcon
  },
  "unity streaming": {
    icon: UnityIcon
  },
  kuberay: {
    icon: RayClusterIcon
  },
  kubeflow: {
    icon: KubeflowIcon
  }
};

export const getNotification = (name: string) => {
  const notification =
    NOTIFICATION_MAP[`${`${name}`.toLowerCase()}` as keyof typeof NOTIFICATION_MAP];

  if (notification) {
    return notification;
  }

  return Object.values(NOTIFICATION_MAP)[0];
};

export const NOTIFICATION_MAP: {
  [k: string]: {
    icon: typeof NvidiaIcon;
    color: string;
    type: NotificationType;
    label: string;
  };
} = {
  // info: {
  //   icon: InfoCircleIcon,
  //   color: "text-blue-light",
  //   type: 'info',
  // },
  [NotificationType.SUCCESS]: {
    icon: TickCircleIcon,
    color: "text-cyan",
    type: NotificationType.SUCCESS,
    label: "Success"
  },
  [NotificationType.WARNING]: {
    icon: AlertTriangleIcon,
    color: "text-yellow",
    type: NotificationType.WARNING,
    label: "Warning"
  },
  [NotificationType.ERROR]: {
    icon: ErrorCircleIcon,
    color: "text-red-light-800",
    type: NotificationType.ERROR,
    label: "Failed"
  }
};

const durationPlaceholderMap = {
  hourly: "Hours",
  daily: "Days",
  weekly: "Weeks"
};

export const getDurationPlaceholder = (durationType: string) => {
  return durationPlaceholderMap[durationType as keyof typeof durationPlaceholderMap];
};

const operatingSystemMap = {
  ubuntu: "linux",
  macos: "mac"
};

export const getOperatingSystem = (value: string) => {
  const words = value.split(" ");

  value = words[0];

  const normalisedValue =
    operatingSystemMap[value.toLowerCase() as keyof typeof operatingSystemMap];
  if (normalisedValue) {
    return normalisedValue;
  }
  return value.toLowerCase();
};

const OPERATING_SYSTEM_ICON_MAP = {
  linux: UbuntuOSIcon,
  windows: WindowsOSIcon,
  mac: MacOSIcon
};

export const getOperatingSystemIcon = (operatingSystem: string) => {
  if (operatingSystem in OPERATING_SYSTEM_ICON_MAP) {
    return OPERATING_SYSTEM_ICON_MAP[operatingSystem as keyof typeof OPERATING_SYSTEM_ICON_MAP];
  }
};

// also update tailwind config safelist
export const STATUS_COLORS = {
  all: {
    color: "#666666",
    colorClass: "bg-gray-dark-1500 text-gray-dark-1500"
  },
  running: {
    color: "#50E3C2",
    colorClass: "bg-cyan-light-100 text-cyan-light-100"
  },
  computed: {
    color: "#50E3C2",
    colorClass: "bg-cyan-light-100 text-cyan-light-100"
  },
  up: {
    color: "#50E3C2",
    colorClass: "bg-cyan-light-100 text-cyan-light-100"
  },
  deploying: {
    color: "#F7B955",
    colorClass: "bg-yellow-light-400 text-yellow-light-400"
  },
  deploymentRequested: {
    color: "#F7B955",
    colorClass: "bg-yellow-light-400 text-yellow-light-400"
  },
  "deployment requested": {
    color: "#F7B955",
    colorClass: "bg-yellow-light-400 text-yellow-light-400"
  },
  terminationRequested: {
    color: "#AB570A",
    colorClass: "bg-yellow-dark-100 text-yellow-dark-100"
  },
  "termination requested": {
    color: "#AB570A",
    colorClass: "bg-yellow-dark-100 text-yellow-dark-100"
  },
  failed: {
    color: "#EF4444",
    colorClass: "bg-red-dark-300 text-red-dark-300"
  },
  inactive: {
    color: "#EF4444",
    colorClass: "bg-red-dark-300 text-red-dark-300"
  },
  destroyed: {
    color: "#666666",
    colorClass: "bg-gray-dark-1500 text-gray-dark-1500"
  },
  terminated: {
    color: "#EF4444",
    colorClass: "bg-red-dark-300 text-red-dark-300"
  },
  unsupported: {
    color: "#666666",
    colorClass: "bg-gray-dark-1500 text-gray-dark-1500"
  },
  blocked: {
    color: "#666666",
    colorClass: "bg-gray-dark-1500 text-gray-dark-1500"
  },
  restartequired: {
    color: "#EF4444",
    colorClass: "bg-red-dark-300 text-red-dark-300"
  },
  "restart required": {
    color: "#EF4444",
    colorClass: "bg-red-dark-300 text-red-dark-300"
  },
  pending: {
    color: "#F5A623",
    colorClass: "bg-yellow text-yellow"
  },
  "in progress": {
    color: "#F5A623",
    colorClass: "bg-yellow text-yellow"
  },
  down: {
    color: "#ccc",
    colorClass: "bg-gray text-gray"
  },
  unpaid: {
    color: "#F7B955",
    colorClass: "bg-yellow-light-400 text-yellow-light-400"
  },
  completed: {
    color: "#FAFAFA",
    colorClass: "bg-gray-light-1000 text-gray-light-1000"
  },
  paused: {
    color: "#F7B955",
    colorClass: "bg-yellow-light-400 text-yellow-light-400"
  },
  archived: {
    color: "#0096FF",
    colorClass: "bg-blue text-blue"
  },
  processing: {
    color: "#0096FF",
    colorClass: "bg-blue text-blue"
  },
  maintenance: {
    color: "#0096FF",
    colorClass: "bg-blue text-blue"
  },
  idle: {
    color: "#666666",
    colorClass: "bg-gray-dark-1500 text-gray-dark-1500"
  }
} as Record<string, { color: string; colorClass: string }>;

export const CONNECTIVITY_TIER_ICONS = [
  {
    value: "tier1",
    label: "Low Speed",
    icon: () => "🐌"
  },
  {
    value: "tier2",
    label: "Medium Speed",
    icon: () => "⚡️"
  },
  {
    value: "tier3",
    label: "High Speed",
    icon: () => "⚡️⚡️"
  },
  {
    value: "tier4",
    label: "Ultra High Speed",
    icon: () => "⚡️⚡️⚡️"
  }
] as {
  value: string;
  label: string;
  icon: typeof NvidiaIcon;
}[];

export const ACCOUNT_SETTINGS_ROUTES = [
  {
    label: "Account Settings",
    href: "/id/account/settings",
    disabled: false
  },
  {
    label: "User Acknowledgment",
    href: "/id/account/acknowledgment",
    disabled: false
  },
  // {
  //   label: "Notification settings",
  //   href: "/id/account/notification",
  //   disabled: true
  // },
  {
    label: "Connected Apps",
    href: "/id/account/security",
    disabled: true
  }
  // {
  //   label: "Support Tickets",
  //   href: "/id/account/support",
  //   disabled: true
  // }
] as {
  label: string;
  href: string;
  disabled: boolean;
}[];
