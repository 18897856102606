import env from "@/env";
import { addExternalScript } from ".";
import { Logtail } from "@logtail/browser";

let isInstalled = false;

export const installBetterstack = async () => {
  if (!env.BETTERSTACK_ENABLED || isInstalled) {
    return;
  }
  isInstalled = true;

  try {
    await addExternalScript("https://uptime.betterstack.com/widgets/announcement.js");
  } catch (e) {
    isInstalled = false;
  }
};

export const logtail = new Logtail(env.BETTERSTACK_SOURCE_TOKEN);

export const logEvent = (eventName: string, params: Record<string, unknown>) => {
  try {
    console.log("logging betterstack event", eventName, params);

    logtail.info(eventName, params);
    logtail.flush();
  } catch (e) {
    console.log(e);
  }
};
